import {Icon} from "@sirdata/ui-lib";
import clsx from "clsx";
import {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {DataSelection} from "../../../api/model/data-selection/DataSelection";
import {Locale} from "../../../common/utils/Locale";
import {TranslationPortalFile} from "../../../utils/constants";

type DataSelectionItemProps = {
    dataSelection: DataSelection;
    pinned?: boolean;
};

const DataSelectionItem: FunctionComponent<DataSelectionItemProps> = ({dataSelection, pinned}) => {
    const {t: textDataSelection} = useTranslation(TranslationPortalFile.DATA_SELECTION);

    return (
        <a
            className={clsx("data-selection__item", {"data-selection__item--pinned": pinned})}
            style={{backgroundImage: `url("${dataSelection.content.preview_image || ""}")`}}
            href={dataSelection.getRoute()}
            rel="noopener noreferrer"
        >
            {pinned &&
                <span className="data-selection__item__pinned-tag">
                    <Icon name="keep"/>
                    <span className="data-selection__item__pinned-tag__label">{textDataSelection("pinned_selection")}</span>
                </span>
            }
            <span className="data-selection__item__title">{Locale.isFrench() ? dataSelection.content.name_fr : dataSelection.content.name_en}</span>
            <span className="data-selection__item__tag">{textDataSelection("nb_item", {count: dataSelection.content.size})}</span>
            <div className="data-selection__item__filter">
                <div className="data-selection__item__filter__button">
                    <span>{textDataSelection("discover")}</span>
                    <Icon name="east" cssClass="data-selection__item__filter__button__icon"/>
                </div>
            </div>
        </a>
    );
};

export default DataSelectionItem;
