import {Account} from "../common/api/model/account/Account";
import {CommonApiSession} from "../common/api/CommonApiSession";
import {FolderFavorites} from "./model/FolderFavorites";
import {PartnerAdsConfig} from "./model/partner-data/PartnerAdsConfig";
import {RestAudiencePortal} from "./rest/RestAudiencePortal";
import {RestCampaignBrief} from "./rest/RestCampaignBrief";
import {RestCustomCategory} from "./rest/RestCustomCategory";
import {RestCuratedDeal} from "./rest/RestCuratedDeal";
import {RestDataSelection} from "./rest/RestDataSelection";
import {RestDistribution} from "./rest/RestDistribution";
import {RestFavorite} from "./rest/RestFavorite";
import {RestPartnerData} from "./rest/RestPartnerData";
import {RestTaxonomy} from "./rest/RestTaxonomy";
import {TaxonomyElement} from "./model/taxonomy/TaxonomyElement";
import {TaxonomyPathItem} from "./model/taxonomy/TaxonomyNode";
import {TaxonomyElementType} from "./model/taxonomy/TaxonomyElementType";
import {Distribution} from "./model/distribution/Distribution";

export class ApiSession extends CommonApiSession {
    private _adsConfig: PartnerAdsConfig | undefined;
    private _curatedDealPlatforms: string[] | undefined;
    private _favoriteFolders: FolderFavorites[] | undefined;
    private _verticals: TaxonomyPathItem[] | undefined;
    private _distributions: Distribution[] | undefined;
    private _distributionsByType: Map<TaxonomyElementType, Distribution[]> = new Map<TaxonomyElementType, Distribution[]>();

    private _restAudiencePortal: RestAudiencePortal | undefined;
    private _restCampaignBrief: RestCampaignBrief | undefined;
    private _restCustomCategory: RestCustomCategory | undefined;
    private _restCuratedDeal: RestCuratedDeal | undefined;
    private _restDataSelection: RestDataSelection | undefined;
    private _restDistribution: RestDistribution | undefined;
    private _restFavorite: RestFavorite | undefined;
    private _restPartnerData: RestPartnerData | undefined;
    private _restTaxonomy: RestTaxonomy | undefined;

    get restAudiencePortal(): RestAudiencePortal {
        return this._restAudiencePortal !== undefined ? this._restAudiencePortal : this._restAudiencePortal = new RestAudiencePortal(this._commonClient);
    }

    get restCampaignBrief(): RestCampaignBrief {
        return this._restCampaignBrief !== undefined ? this._restCampaignBrief : this._restCampaignBrief = new RestCampaignBrief(this._commonClient);
    }

    get restCustomCategory(): RestCustomCategory {
        return this._restCustomCategory !== undefined ? this._restCustomCategory : this._restCustomCategory = new RestCustomCategory(this._commonClient);
    }

    get restCuratedDeal(): RestCuratedDeal {
        return this._restCuratedDeal !== undefined ? this._restCuratedDeal : this._restCuratedDeal = new RestCuratedDeal(this._commonClient);
    }

    get restDataSelection(): RestDataSelection {
        return this._restDataSelection !== undefined ? this._restDataSelection : this._restDataSelection = new RestDataSelection(this._commonClient);
    }

    get restDistribution(): RestDistribution {
        return this._restDistribution !== undefined ? this._restDistribution : this._restDistribution = new RestDistribution(this._commonClient);
    }

    get restFavorite(): RestFavorite {
        return this._restFavorite !== undefined ? this._restFavorite : this._restFavorite = new RestFavorite(this._commonClient);
    }

    get restPartnerData(): RestPartnerData {
        return this._restPartnerData !== undefined ? this._restPartnerData : this._restPartnerData = new RestPartnerData(this._commonClient);
    }

    get restTaxonomy(): RestTaxonomy {
        return this._restTaxonomy !== undefined ? this._restTaxonomy : this._restTaxonomy = new RestTaxonomy(this._commonClient);
    }

    async getAccount(): Promise<Account> {
        const account = await super.getAccount();
        return account as Account;
    }

    async getAdsConfig(): Promise<PartnerAdsConfig | undefined> {
        if (this._adsConfig === undefined) {
            await this.loadAds();
        }
        return this._adsConfig as PartnerAdsConfig;
    }

    async getAdsId(id: number): Promise<number | undefined> {
        const adsConfig = await this.getAdsConfig();
        return adsConfig?.getMapId(id);
    }

    async getCuratedDealPlatforms(): Promise<string[]> {
        if (this._curatedDealPlatforms === undefined) {
            this._curatedDealPlatforms = await this.restCuratedDeal.getPlatforms();
        }
        return this._curatedDealPlatforms as string[];
    }

    async getFavoriteFolders(): Promise<FolderFavorites[]> {
        if (this._favoriteFolders === undefined && this.partnerId) {
            this._favoriteFolders = await this.loadFavoriteFolders();
        }
        return this._favoriteFolders || [];
    }

    async getVerticals(): Promise<TaxonomyPathItem[]> {
        if (this._verticals === undefined) {
            const nodeResult = await session.restTaxonomy.getNode();
            this._verticals = (nodeResult.children || []).map((it) => it.path_element);
        }
        return this._verticals as TaxonomyPathItem[];
    }

    async getDistributions(): Promise<Distribution[]> {
        if (this._distributions === undefined) {
            const distributions = await session.restDistribution.list();
            distributions.unshift(Distribution.SIRDATA_API);
            this._distributions = distributions;
        }
        return this._distributions as Distribution[];
    }

    async getDistributionsByType(type: TaxonomyElementType): Promise<Distribution[]> {
        if (this._distributionsByType.get(type) === undefined) {
            const distributions: Distribution[] = [];
            switch (type) {
                case TaxonomyElementType.SEGMENT:
                    distributions.push(Distribution.SIRDATA_API);
                    break;
                case TaxonomyElementType.CATEGORY:
                    distributions.push(Distribution.SIRDATA_API);
                    distributions.push(Distribution.SIRDATA_PREBID);
                    break;
            }
            const items = await session.restDistribution.list(type);
            distributions.push(...items);
            this._distributionsByType.set(type, distributions);
        }
        return this._distributionsByType.get(type) as Distribution[];
    }

    async loadAds() {
        const adsConfigs = await this.restPartnerData.getAdsConfigs();
        if (adsConfigs.length) {
            this._adsConfig = adsConfigs[0];
        }
    }

    async loadFavoriteFolders(): Promise<FolderFavorites[]> {
        this._favoriteFolders = await this.restFavorite.list();
        return this._favoriteFolders;
    }

    async toggleFavoriteElement(folderId: number, element: TaxonomyElement) {
        if (!this._favoriteFolders) return;
        const folder = this._favoriteFolders.find((folder) => folder.id === folderId);
        if (folder?.hasElement(element)) {
            await this.restFavorite.removeElement(folderId, element);
        } else {
            await this.restFavorite.addElement(folderId, element);
        }
    }
}

export const session = new ApiSession();
