import {Rest} from "../../common/api/rest/Rest";
import {TaxonomyElementType} from "../model/taxonomy/TaxonomyElementType";
import {Distribution} from "../model/distribution/Distribution";

export class RestDistribution extends Rest {
    rootPath = "/audience-api/distribution";

    list(type?: TaxonomyElementType): Promise<Distribution[]> {
        return this._client.get(this.rootPath, Distribution, {queryParams: {taxonomyType: type}}) as Promise<Distribution[]>;
    }
}
