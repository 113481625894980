import {Rest} from "../../common/api/rest/Rest";
import {DataSelectionSearchResponse} from "../model/data-selection/DataSelectionSearchResponse";
import {DataSelection} from "../model/data-selection/DataSelection";
import {DataSelectionSearchQuery} from "../model/data-selection/DataSelectionSearchQuery";

export class RestDataSelection extends Rest {
    rootPath = "/audience-api/data-selection";

    search(params: DataSelectionSearchQuery): Promise<DataSelectionSearchResponse> {
        return this._client.get(`${this.rootPath}`, DataSelectionSearchResponse, {queryParams: params.getJsonParameters()}) as Promise<DataSelectionSearchResponse>;
    }

    list(theme?: string): Promise<DataSelection[]> {
        return this._client.get(`${this.rootPath}/list?theme=${theme || ""}`, DataSelection) as Promise<DataSelection[]>;
    }

    get(slug: string): Promise<DataSelection> {
        return this._client.get(`${this.rootPath}/${slug}`, DataSelection) as Promise<DataSelection>;
    }
}
