import React from "react";

const TagEuid: React.FC = () => {
    return (
        <span className="tag-euid">
            <img src="/images/logo_euid.png" alt="TheTradeDesk EUID"/>
        </span>
    );
};

export default TagEuid;
