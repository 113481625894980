import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import copy from "copy-to-clipboard";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {FavoriteButton, SegmentPlatforms, TagEuid, TagKantarMedia, TagNew, TagSegmentType} from "../index";
import {Segment} from "../../../api/model/segment/Segment";
import {Action, Icon, IconTooltip} from "@sirdata/ui-lib";
import {getSegmentStepFromValue} from "../../../api/model/segment/SegmentStepSize";
import {TaxonomyFilters} from "../../../utils/taxonomy/TaxonomyFilters";
import {getTaxonomyTagColor, getTaxonomyTagIcon} from "../../../utils/taxonomy/TaxonomyTag";
import {TaxonomyTargetingType} from "../../../utils/taxonomy/TaxonomyTargetingType";
import {TaxonomyActivationType} from "../../../utils/taxonomy/TaxonomyActivationType";
import TagCookieless from "../taxonomy/TagCookieless";
import {getTaxonomyTopTierIcon} from "../../../api/model/taxonomy/TaxonomyTopTier";
import clsx from "clsx";
import {SegmentDataType} from "../../../api/model/segment/SegmentDataType";

type SearchTableSegmentProps = {
    item: Segment;
    filters: TaxonomyFilters;
};

const SearchSegment: FunctionComponent<SearchTableSegmentProps> = ({item, filters}) => {
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const isMounted = useRef(false);
    const [isPriceHighlighted, setPriceHighlighted] = useState(false);
    const [isCopiedName, setCopiedName] = useState(false);

    useEffect(() => {
        if (!isMounted.current) return;
        setTimeout(() => {
            setPriceHighlighted(false);
        }, 800);
        setPriceHighlighted(true);
    }, [filters.currency]);

    useEffect(() => {
        isMounted.current = true;
    }, [item]);

    const handleCopyName = () => {
        copy(`${item.id} | ${item.full_name}`);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    return (
        <div className="search-element">
            <a href={item.getRoute()} className="element-column" style={{background: getTaxonomyTagColor(TaxonomyTargetingType.USER)}}>
                <div className="element-column__content">
                    <Icon {...getTaxonomyTagIcon(TaxonomyTargetingType.USER) || {name: ""}} cssClass="element__type__icon"/>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column"> </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content">
                    <div className="element__text">
                        <Icon {...getTaxonomyTopTierIcon(item.top_path_element)}/>
                        <span>{item.top_path_element?.name}</span>
                    </div>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content">
                    <div className="element__text element__text--bold">
                        <span>{item.name}</span>
                        {item.description &&
                        <IconTooltip
                            icon={{name: "info", outlined: true}}
                            text={item.description}
                            cssClass={"element__text__icon-tooltip"}
                        />
                        }
                        {item.isNew() && <TagNew/>}
                        <TagSegmentType type={item.data_type}/>
                        {item.cookieless && <TagCookieless/>}
                        {item.hasDataType(SegmentDataType.MODELING) && <TagKantarMedia/>}
                        {item.isTheTradeDeskEUID() && <TagEuid/>}
                    </div>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content element-column__content--justify-center">
                    <IconTooltip
                        icon={getTaxonomyTagIcon(TaxonomyActivationType.SELF_SERVICE) || {name: ""}}
                        text={textTaxonomy(`tag.${TaxonomyActivationType.SELF_SERVICE}`)}
                        cssClass={"element__icon"}
                    />
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content element-column__content--justify-center">
                    <span className="element__info">{getSegmentStepFromValue(item.step_volume)}</span>
                </div>
            </a>
            <a href={item.getRoute()} className="element-column">
                <div className="element-column__content element-column__content--justify-center">
                    <span className={clsx("element__info", {"element__info--highlighted": isPriceHighlighted})}>{item.getPrice(filters.currency.currency) / 100} {`${filters.currency.symbol}`}</span>
                </div>
            </a>
            <div className="element-column" onClick={(e) => e?.stopPropagation()}>
                <div className="element-column__content element-column__content--justify-center">
                    <div className="element__actions">
                        <div className="element__actions__item">
                            <IconTooltip
                                icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                                text={textTaxonomy("action.copy_segment_name")}
                                onClick={handleCopyName}
                            />
                        </div>
                        <div className="element__actions__item">
                            <FavoriteButton element={item.toTaxonomyElement()}/>
                        </div>
                        <div className="element__actions__item">
                            <SegmentPlatforms segment={item} platform={filters.platform}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchSegment;
