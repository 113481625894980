import {Rest} from "../../common/api/rest/Rest";
import {CuratedDealRequest} from "../model/curated-deal/CuratedDealRequest";
import {Distribution} from "../model/distribution/Distribution";

export class RestCuratedDeal extends Rest {
    rootPath = "/audience-api/curated-deal";

    getInventories(): Promise<Distribution[]> {
        return this._client.get(`${this.rootPath}/inventory`, Distribution) as Promise<Distribution[]>;
    }

    getPlatforms(): Promise<string[]> {
        return this._client.get(`${this.rootPath}/platform`) as Promise<string[]>;
    }

    sendCuratedDealRequest(request: CuratedDealRequest): Promise<void> {
        return this._client.post(`${this.rootPath}/request`, request);
    };
}
