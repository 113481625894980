import React, {FunctionComponent} from "react";
import {Distribution} from "../../api/model/distribution/Distribution";

type DistributionLogoProps = {
    distribution: Distribution;
    cssClass?: string;
};

const DistributionLogo: FunctionComponent<DistributionLogoProps> = ({distribution, cssClass}) => {
    if (!distribution) {
        return <></>;
    }

    return (
        <img src={distribution.image_url} alt={`Logo ${distribution.label}`} className={cssClass}/>
    );
};

export default DistributionLogo;
