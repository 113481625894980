import {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, Alert, AlertItem, AlertSeverity, Button, ButtonSize, ButtonStyle, FieldBlock, FlexContentSpacing, FormLayoutButtons, FormLayoutColumns, FormLayoutMention, FormLayoutRows, InputText, ModalContent, ModalNew, ModalTitle, SelectAutocomplete, TranslationLibFile} from "@sirdata/ui-lib";
import {CuratedDeal} from "../../api/model/curated-deal/CuratedDeal";
import {CuratedDealRequest} from "../../api/model/curated-deal/CuratedDealRequest";
import {session} from "../../api/ApiSession";
import {Account} from "../../common/api/model/account/Account";
import {TranslationPortalFile} from "../../utils/constants";
import {TagActivationType, TagCookieless, TagTaxonomy} from "../snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {TaxonomyActivationType} from "../../utils/taxonomy/TaxonomyActivationType";
import {TaxonomyTargetingType} from "../../utils/taxonomy/TaxonomyTargetingType";
import {FormLayoutMessage} from "../../common/component/snippet";

type ModalCuratedDealRequestProps = {
    onClose?: () => void;
}

type ModalCuratedDealRequestForm = {
    curatedDeal: CuratedDeal;
    platform?: string;
    seat_id?: string;
};

export type EventCuratedDealRequestArgs = {
    curatedDeal: CuratedDeal;
    platform?: string;
}

const ModalCuratedDealRequest: FunctionComponent<ModalCuratedDealRequestProps> = ({onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCuratedDeals} = useTranslation(TranslationPortalFile.CURATED_DEALS);
    const [isActive, setActive] = useState<boolean>(false);
    const [account, setAccount] = useState<Account>();
    const [platforms, setPlatforms] = useState<string[]>([]);
    const [form, setForm] = useState<ModalCuratedDealRequestForm>({curatedDeal: new CuratedDeal()});
    const [isShowError, setShowError] = useState(false);
    const [message, setMessage] = useState<AlertItem>();

    useEffect(() => {
        UIEventManager.addListener(ModalCuratedDealRequestUiEvent, (args: EventCuratedDealRequestArgs) => {
            if (args?.curatedDeal) {
                setForm({
                    curatedDeal: args.curatedDeal,
                    platform: args.platform || "",
                    seat_id: ""
                });
                setActive(true);
            }
        });

        return () => {
            UIEventManager.removeAllListeners(ModalCuratedDealRequestUiEvent);
        };
    }, []);

    useEffect(() => {
        (async function () {
            try {
                const platforms = await session.getCuratedDealPlatforms();
                setPlatforms(platforms);

                const account = await session.getAccount();
                setAccount(account);
            } catch (e) {}
        })();
    }, []);

    const handleChangeSeatId = (value: string) => {
        if (isShowError) {
            setShowError(false);
        }
        setForm((prevState) => ({...prevState, seat_id: value}));
    };

    const handleSubmit = async () => {
        setShowError(false);

        const request = new CuratedDealRequest();
        request.load({
            email: account?.email,
            title: form.curatedDeal.name,
            vertical: form.curatedDeal.top_path_element?.name,
            dsp_name: form.platform,
            seat_id : form.seat_id
        });

        try {
            await session.restCuratedDeal.sendCuratedDealRequest(request);
            setMessage({text: textCuratedDeals("modal.request_success"), severity: AlertSeverity.SUCCESS});
            setTimeout(() => handleClose(), 5000);
        } catch (e) {
            setShowError(true);
        }
    };

    const handleClose = () => {
        setMessage(undefined);
        setActive(false);
        onClose && onClose();
    };

    const hasEmptyField = () => !form.platform || !form.seat_id;

    return (
        <ModalNew onClose={handleClose} active={isActive}>
            <ModalContent>
                <ModalTitle>{textCuratedDeals("modal.title")}</ModalTitle>
                <FormLayoutRows>
                    <div className="modal-deal-details">
                        <div className="modal-deal-details__parent">
                            {form.curatedDeal.top_path_element?.name}
                        </div>
                        <div className="modal-deal-details__title">
                            {form.curatedDeal.name}
                        </div>
                        <div className="modal-deal-details__tags">
                            {form.curatedDeal.isUserBased() && <TagTaxonomy type={TaxonomyTargetingType.USER}/>}
                            {form.curatedDeal.isContextual() && <TagTaxonomy type={TaxonomyTargetingType.CONTEXTUAL}/>}
                            {form.curatedDeal.isContextual() && <TagCookieless/>}
                            <TagActivationType type={TaxonomyActivationType.DEAL}/>
                        </div>
                    </div>
                    <FormLayoutColumns>
                        <FieldBlock label={textCuratedDeals("modal.platform")} required>
                            <SelectAutocomplete
                                value={form.platform}
                                options={platforms.map((it) => ({label: it, value: it}))}
                                placeholder={textCuratedDeals("modal.platform_placeholder")}
                                onChange={(option) => setForm((prevState) => ({...prevState, platform: option?.value.toString()}))}
                                clearable
                            />
                        </FieldBlock>
                        <FieldBlock label={textCuratedDeals("modal.seat_id")} required>
                            <InputText
                                placeholder={textCuratedDeals("modal.seat_id_placeholder")}
                                value={form.seat_id}
                                onChange={handleChangeSeatId}
                            />
                        </FieldBlock>
                    </FormLayoutColumns>
                    <FormLayoutMention/>
                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                        <FormLayoutButtons>
                            <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} disabled={hasEmptyField()} onClick={handleSubmit}>
                                {textCommon(Action.SEND.labelKey)}
                            </Button>
                        </FormLayoutButtons>
                        {isShowError && <FormLayoutMessage message={textCuratedDeals("modal.request_error")} severity={AlertSeverity.DANGER}/>}
                    </FormLayoutRows>
                    {message &&
                    <Alert text={message.text} severity={message.severity}/>
                    }
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalCuratedDealRequest;
export const ModalCuratedDealRequestUiEvent = "ModalCuratedDealRequest";
