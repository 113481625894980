import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {EventCuratedDealRequestArgs, ModalCuratedDealRequestUiEvent} from "../component/modal/ModalCuratedDealRequest";
import {DistributionLogo, FavoriteButton, TagActivationType, TagTaxonomy} from "../component/snippet";
import {CuratedDeal} from "../api/model/curated-deal/CuratedDeal";
import {MainContent, Wrapper} from "../common/component/widget";
import {SirdataApiEvent} from "../common/api/CommonApiClient";
import {session} from "../api/ApiSession";
import {TranslationPortalFile} from "../utils/constants";
import {Formatter} from "../common/utils/Formatter";
import {defaultCurrencyItem} from "../api/model/Currency";
import {getIconForCuratedDealRecommendationFormatType} from "../api/model/curated-deal/CuratedDealRecommendationFormatType";
import copy from "copy-to-clipboard";
import {UIEventManager} from "../common/utils/UIEventManager";
import {Action, Button, ButtonSize, ButtonStyle, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentMobileDirection, FlexContentSpacing, Icon, IconTooltip, LayoutRows, Paragraph, SvgSirdataChip, Tooltip, TranslationLibFile} from "@sirdata/ui-lib";
import {TaxonomyActivationType} from "../utils/taxonomy/TaxonomyActivationType";
import {TaxonomyTargetingType} from "../utils/taxonomy/TaxonomyTargetingType";
import {getTaxonomyTagColor} from "../utils/taxonomy/TaxonomyTag";
import {TaxonomyElementType} from "../api/model/taxonomy/TaxonomyElementType";
import {getCuratedDealStepFromValue} from "../api/model/curated-deal/CuratedDealStepSize";
import TagCookieless from "../component/snippet/taxonomy/TagCookieless";
import {MainHeader} from "../common/component/snippet";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";
import {Distribution} from "../api/model/distribution/Distribution";

function Deal() {
    const {id: dealId} = useParams() as {id: string};
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textCuratedDeals} = useTranslation(TranslationPortalFile.CURATED_DEALS);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [isCopiedName, setCopiedName] = useState(false);

    const [deal, setDeal] = useState<CuratedDeal>();
    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [inventories, setInventories] = useState<Distribution[]>([]);

    const currency = defaultCurrencyItem;
    const elementColor = (() => {
        if (deal?.isUserBased() && deal?.isContextual()) return getTaxonomyTagColor(TaxonomyActivationType.DEAL);
        if (deal?.isUserBased()) return getTaxonomyTagColor(TaxonomyTargetingType.USER);
        if (deal?.isContextual()) return getTaxonomyTagColor(TaxonomyTargetingType.CONTEXTUAL);
        return getTaxonomyTagColor(TaxonomyActivationType.DEAL);
    })();

    useEffect(() => {
        (async () => {
            try {
                const element = await session.restTaxonomy.getElement(TaxonomyElementType.CURATED_DEAL, +dealId);
                if (element?.audience_curated_deal) {
                    setDeal(element.audience_curated_deal);
                } else {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
                console.error("Failed to load deal:", e);
            }
        })();
    }, [dealId]);

    useEffect(() => {
        if (!deal) return;
        (async () => {
            try {
                const distributions = await session.getDistributionsByType(TaxonomyElementType.CURATED_DEAL);
                setDistributions(distributions);

                const inventories = await session.restCuratedDeal.getInventories();
                setInventories(inventories);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load distributions:", e);
                }
            }
        })();
    }, [deal]);

    const handleCopyName = (deal: CuratedDeal) => {
        copy(deal.name);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    if (!deal) return (<></>);

    const handleRequestDeal = (distribution?: Distribution) => {
        UIEventManager.emit(ModalCuratedDealRequestUiEvent, {
            curatedDeal: deal,
            platform: distribution?.label
        } as EventCuratedDealRequestArgs);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <div className="element-details">
                    <div className="details__skin" style={{background: `radial-gradient(circle at -4rem -2rem, ${elementColor} 34.9rem, ${Color.WHITE} 35rem)`}}>
                        <SvgSirdataChip currentColor={Color.WHITE}/>
                    </div>
                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <div className="details__main">
                            <div className="details__main__row">
                                <div className="details__main__header">
                                    {deal.isUserBased() && <TagTaxonomy type={TaxonomyTargetingType.USER}/>}
                                    {deal.isContextual() && <TagTaxonomy type={TaxonomyTargetingType.CONTEXTUAL}/>}
                                    <div className="details__main__header__actions">
                                        <div className="details__main__header__actions__item">
                                            <IconTooltip
                                                icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                                                text={textTaxonomy("action.copy_deal_name")}
                                                onClick={() => handleCopyName(deal)}
                                            />
                                        </div>
                                        <div className="details__main__header__actions__item">
                                            <FavoriteButton element={deal.toTaxonomyElement()}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="details__main__parent">
                                    {deal.top_path_element?.name}
                                </div>
                                <h1 className="details__main__title">
                                    <span>{deal.name}</span>
                                </h1>
                                <div className="details__main__description">{deal.description}</div>
                                <div className="details__main__tags">
                                    {deal.isContextual() && <TagCookieless/>}
                                    <TagActivationType type={TaxonomyActivationType.DEAL}/>
                                </div>
                            </div>
                            <div className="details__main__row">
                                <div className="details__main__infos" style={{color: elementColor}}>
                                    <div className="details__main__infos__info">
                                        <Tooltip text={textCuratedDeals("tooltip.impressions")} cssClass="details__main__infos__info__name">
                                            <Icon name="equalizer" cssClass="details__main__infos__info__name__icon"/>
                                            <span>{textTaxonomy("info.prints")}</span>
                                        </Tooltip>
                                        <div className="details__main__infos__info__value">
                                            {getCuratedDealStepFromValue(deal.getTotalImpressions())}
                                        </div>
                                    </div>
                                    <div className="details__main__infos__info">
                                        <Tooltip text={textCuratedDeals("tooltip.minimum_cpm")} cssClass="details__main__infos__info__name">
                                            <Icon name="sell" cssClass="details__main__infos__info__name__icon"/>
                                            <span>{textTaxonomy("info.cpm")}</span>
                                        </Tooltip>
                                        <div className="details__main__infos__info__value">
                                            {Formatter.formatNumber(deal.getFloorPrice(currency.currency) / 100)} {`${currency.symbol}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="details__main__sub-infos" style={{color: elementColor}}>
                                    <div className="details__main__sub-infos__info">
                                        <Icon name="language"/>
                                        <span>{deal.getCountriesAsString()}</span>
                                    </div>
                                    <div className="details__main__sub-infos__info">
                                        <Icon name="devices" outlined/>
                                        <span>{deal.device_type.map((it) => it && textCuratedDeals(`device.${it}`)).join(", ")}</span>
                                    </div>
                                    <div className="details__main__sub-infos__info">
                                        <Icon name="my_location"/>
                                        <span>{textCuratedDeals("details.inventory_type", {type: deal.inventory_type.map((it) => it && textCuratedDeals(`inventory.${it.toUpperCase()}`)).join(", "), count: deal.inventory_type.length})}</span>
                                    </div>
                                    {deal.getRecommendations().map(({format_type, impression}) =>
                                        <div className="details__main__sub-infos__info" key={format_type}>
                                            <Icon {...getIconForCuratedDealRecommendationFormatType(format_type)}/>
                                            <span>{textCuratedDeals(`format.${format_type}`)} <strong style={{color: elementColor}}>{Formatter.formatNumber(impression)} impressions</strong></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <LayoutRows cssClass={"details__more"}>
                            <div className="details__more__section">
                                <h2 className="details__more__section__title">
                                    <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                    <span>{textTaxonomy("details.distribution.title")}</span>
                                </h2>
                                <Paragraph cssClass={"details__more__section__text"} withSpacing>{textTaxonomy("details.distribution.text")}</Paragraph>
                                <div className="details__more__section__content">
                                    <div className="details__more__section__platforms">
                                        {distributions.map((distribution) =>
                                            <div className="details__more__section__platforms__item" key={distribution.name}>
                                                <div className="details__more__section__platforms__item__platform">
                                                    <div className="details__more__section__platforms__item__platform__logo">
                                                        <DistributionLogo distribution={distribution}/>
                                                    </div>
                                                    <div className="details__more__section__platforms__item__platform__name">{distribution.label}</div>
                                                </div>
                                                <div className="details__more__section__platforms__item__value">
                                                    <div className="details__more__section__platforms__item__value__action">
                                                        <Button
                                                            size={ButtonSize.SMALL}
                                                            style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                            icon={{name: "ios_share"}}
                                                            onClick={() => handleRequestDeal(distribution)}
                                                        >
                                                            {textCommon(Action.ACTIVATE.labelKey)}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="details__more__section__platforms__item">
                                            <div className="details__more__section__platforms__item__platform">
                                                <div className="details__more__section__platforms__item__platform__logo">
                                                    <Icon name="more_horiz" colorIcon={Color.MIDNIGHT_LIGHT}/>
                                                </div>
                                                <div className="details__more__section__platforms__item__platform__name">{textCuratedDeals("details.other")}</div>
                                            </div>
                                            <div className="details__more__section__platforms__item__value">
                                                <div className="details__more__section__platforms__item__value__action">
                                                    <Button
                                                        size={ButtonSize.SMALL}
                                                        style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                        icon={{name: "ios_share"}}
                                                        onClick={() => handleRequestDeal()}
                                                    >
                                                        {textCommon(Action.ACTIVATE.labelKey)}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details__more__section">
                                <h2 className="details__more__section__title">
                                    <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                    <span>{textCuratedDeals("details.available_inventory")}</span>
                                </h2>
                                <div className="details__more__section__content">
                                    <div className="details__more__section__cards">
                                        {inventories.map((item) =>
                                            <div className="details__more__section__cards__item" key={item.name}>
                                                <DistributionLogo distribution={item}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </LayoutRows>
                    </FlexContent>
                </div>
            </MainContent>
        </Wrapper>
    );
}

export default Deal;
