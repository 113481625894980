import {routeParamId, routeParamPath} from "../common/utils/constants";

/* ROUTES */
export const pathContextualCategory = "/contextual/category" + routeParamId;

export const pathContextualCustom = "/contextual/custom";
export const pathContextualCustomCategory = pathContextualCustom + routeParamId;
export const pathContextualCustomCategoryKeywords = pathContextualCustomCategory + "/keywords";
export const pathContextualCustomCategoryRelevancy = pathContextualCustomCategory + "/relevancy";

export const pathDataSelection = "/data-selection";
export const pathDataSelectionWithPath = pathDataSelection + routeParamPath;

export const pathDeal = "/deal" + routeParamId;

export const pathExplore = "/explore";

export const pathFavorites = "/favorites";
export const pathFavoritesFolder = pathFavorites + routeParamId;

export const pathFavoritesShort = "/fav";
export const pathFavoritesFolderPublic = pathFavoritesShort + routeParamId;

export const pathPartners = "/partners";

export const pathSearch = "/search";

export const pathUserSegment = "/user/segment" + routeParamId;

/* TRANSLATIONS */
export enum TranslationPortalFile {
    ACCESS = "access",
    CAMPAIGN_BRIEF = "campaign-brief",
    CONTEXTUAL = "contextual",
    CONTEXTUAL_CUSTOM = "contextual-custom",
    CONTEXTUAL_CUSTOM_CONFIGURATION = "contextual-custom-configuration",
    CURATED_DEALS = "curated-deals",
    DATA_SELECTION = "data-selection",
    EXPLORE = "explore",
    FAVORITES = "favorites",
    HOME = "home",
    LEGAL_NOTICE = "legal-notice",
    LOGIN = "login",
    PARTNERS = "partners",
    TAXONOMY = "taxonomy",
    TRANSLATION = "translation"
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.ACCESS,
    TranslationPortalFile.CAMPAIGN_BRIEF,
    TranslationPortalFile.CONTEXTUAL,
    TranslationPortalFile.CONTEXTUAL_CUSTOM,
    TranslationPortalFile.CONTEXTUAL_CUSTOM_CONFIGURATION,
    TranslationPortalFile.CURATED_DEALS,
    TranslationPortalFile.DATA_SELECTION,
    TranslationPortalFile.EXPLORE,
    TranslationPortalFile.FAVORITES,
    TranslationPortalFile.HOME,
    TranslationPortalFile.LEGAL_NOTICE,
    TranslationPortalFile.LOGIN,
    TranslationPortalFile.PARTNERS,
    TranslationPortalFile.TAXONOMY,
    TranslationPortalFile.TRANSLATION
];

export const prebidDocumentationUrl = "https://docs.prebid.org/dev-docs/modules/sirdataRtdProvider.html";
