import {ApiModel} from "../../../common/api/model/ApiModel";

export class Distribution extends ApiModel {
    static SIRDATA_ADS = new Distribution({name: "Sirdata ADS", label: "Sirdata ADS", image_url: "/images/platforms/sirdata-ads.png"});
    static SIRDATA_API = new Distribution({name: "Sirdata API", label: "Sirdata API", image_url: "/images/platforms/sirdata.png"});
    static SIRDATA_PREBID = new Distribution({name: "Sirdata Prebid", label: "Sirdata Prebid", image_url: "/images/platforms/prebid.png"});

    name: string = "";
    label: string = "";
    image_url: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
