import {HeroHeader, LayoutRows, Loadable, MainContentStyle, SearchBarClearable} from "@sirdata/ui-lib";
import clsx from "clsx";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {session} from "../../api/ApiSession";
import {DataSelection} from "../../api/model/data-selection/DataSelection";
import {DataSelectionSearchQuery} from "../../api/model/data-selection/DataSelectionSearchQuery";
import {DataSelectionSearchQueryParam} from "../../api/model/data-selection/DataSelectionSearchQueryParam";
import {DATA_SELECTION_THEMES} from "../../api/model/data-selection/DataSelectionTheme";
import {MainHeader} from "../../common/component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {usePortalContext} from "../../common/context/PortalContext";
import {DataSelectionItem} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";

function DataSelections() {
    const {portalSetting} = usePortalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const {t: textDataSelection} = useTranslation(TranslationPortalFile.DATA_SELECTION);
    const [isLoading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState<DataSelectionSearchQuery>(new DataSelectionSearchQuery());

    const [dataSelections, setDataSelections] = useState<DataSelection[]>([]);
    const [currentDataSelections, setCurrentDataSelections] = useState<DataSelection[]>([]);
    const [currentRows, setCurrentRows] = useState<DataSelection[][]>([]);
    const [pinnedDataSelection, setPinnedDataSelection] = useState<DataSelection>();

    useEffect(() => {
        const searchQuery = new DataSelectionSearchQuery();
        const urlSearchParams = new URL(window.location.href).searchParams;
        searchQuery.search = urlSearchParams.get(DataSelectionSearchQueryParam.SEARCH) || undefined;
        searchQuery.theme = urlSearchParams.get(DataSelectionSearchQueryParam.THEME) || undefined;
        setSearchQuery(searchQuery);
    }, [location]);

    useEffect(() => {
        if (portalSetting.properties.data_selection_id) {
            (async () => {
                try {
                    const results = await session.restDataSelection.list();
                    setPinnedDataSelection(results.find((it) => it.id === portalSetting.properties.data_selection_id));
                } catch (e) {
                    setPinnedDataSelection(undefined);
                }
            })();
        }
    }, [portalSetting]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                let results = await session.restDataSelection.list(searchQuery.theme);
                if (pinnedDataSelection) {
                    results = results.filter((it) => it.id !== pinnedDataSelection.id);
                }
                setDataSelections(results);
            } catch (e) {
                setDataSelections([]);
            } finally {
                setLoading(false);
            }
        })();
    }, [searchQuery.theme, pinnedDataSelection]);

    useEffect(() => {
        let newCurrentDataSelections = [...dataSelections];
        if (searchQuery.search) {
            newCurrentDataSelections = dataSelections.filter((it) => `${it.title} ${it.content.name_fr} ${it.content.name_en}`.toLowerCase().includes(searchQuery.search!.toLowerCase()));
        }
        setCurrentDataSelections(newCurrentDataSelections);
    }, [searchQuery.search, dataSelections]);

    useEffect(() => {
        let rows: DataSelection[][] = [];
        for (let i = 0; i < currentDataSelections.length; i = i + 2) {
            const row: DataSelection[] = [];
            if (currentDataSelections[i]) row.push(currentDataSelections[i]);
            if (currentDataSelections[i + 1]) row.push(currentDataSelections[i + 1]);
            rows.push(row);
        }
        setCurrentRows(rows);
    }, [currentDataSelections]);

    const handleChangeSearchQuery = (param: DataSelectionSearchQueryParam, value: string | undefined) => {
        const newSearchQuery = new DataSelectionSearchQuery({...searchQuery, [param]: value});
        navigate(newSearchQuery.getRoute());
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.FULL_WIDTH}>
                <LayoutRows>
                    <HeroHeader title={textDataSelection("title")}/>
                    <div className="data-selection">
                        <div className="data-selection__search">
                            <SearchBarClearable
                                value={searchQuery.search}
                                onSubmit={(query) => handleChangeSearchQuery(DataSelectionSearchQueryParam.SEARCH, query)}
                                placeholder={textDataSelection("search_placeholder")}
                            />
                        </div>
                        <ul className="data-selection__themes">
                            {[undefined, ...DATA_SELECTION_THEMES].map((theme) => {
                                const themeName = `theme.${theme || "all"}`;
                                return (
                                    <li
                                        key={themeName}
                                        className={clsx("data-selection__theme", {"data-selection__theme--active": searchQuery.theme === theme})}
                                        onClick={() => handleChangeSearchQuery(DataSelectionSearchQueryParam.THEME, theme)}
                                    >
                                        <span>{textDataSelection(themeName)}</span>
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="data-selection__items">
                            {pinnedDataSelection &&
                                <div className="data-selection__row">
                                    <DataSelectionItem dataSelection={pinnedDataSelection} pinned/>
                                </div>
                            }
                            <Loadable loading={isLoading}>
                                {currentRows.map((items, index) => {
                                    const rowKey = `data-selection-row-${index}`;
                                    return (
                                        <div key={rowKey} className="data-selection__row">
                                            {items.map((item) =>
                                                <DataSelectionItem key={item.id} dataSelection={item}/>
                                            )}
                                        </div>
                                    );
                                })}
                            </Loadable>
                        </div>
                    </div>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default DataSelections;
