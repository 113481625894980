import {Action, Button, ButtonSize, ButtonStyle, Color, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentMobileDirection, FlexContentSpacing, Icon, IconTooltip, LayoutRows, Paragraph, SvgSirdataChip, TranslationLibFile} from "@sirdata/ui-lib";
import copy from "copy-to-clipboard";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {session} from "../api/ApiSession";
import {defaultCurrencyItem} from "../api/model/Currency";
import {Segment} from "../api/model/segment/Segment";
import {SegmentDataType} from "../api/model/segment/SegmentDataType";
import {getSegmentStepFromValue} from "../api/model/segment/SegmentStepSize";
import {TaxonomyElementType} from "../api/model/taxonomy/TaxonomyElementType";
import {SirdataApiEvent} from "../common/api/CommonApiClient";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {HttpStatusCode} from "../common/api/http/HttpStatusCode";
import {MainHeader} from "../common/component/snippet";
import {MainContent, Wrapper} from "../common/component/widget";
import {UIEventManager} from "../common/utils/UIEventManager";
import {DistributionLogo, FavoriteButton, TagActivationType, TagCookieless, TagEuid, TagKantarMedia, TagNew, TagUserSegmentType, ValueCopier} from "../component/snippet/index";
import {TranslationPortalFile} from "../utils/constants";
import {TaxonomyActivationType} from "../utils/taxonomy/TaxonomyActivationType";
import {getTaxonomyTagColor} from "../utils/taxonomy/TaxonomyTag";
import {TaxonomyTargetingType} from "../utils/taxonomy/TaxonomyTargetingType";
import {ModalContactUiEvent} from "../common/component/modal/ModalContact";
import {Distribution} from "../api/model/distribution/Distribution";

function UserSegment() {
    const {id: segmentId} = useParams() as {id: string};
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textTaxonomy} = useTranslation(TranslationPortalFile.TAXONOMY);
    const [isCopiedName, setCopiedName] = useState(false);

    const [segment, setSegment] = useState<Segment>();
    const [distributions, setDistributions] = useState<Distribution[]>([]);
    const [distributionIds, setDistributionIds] = useState<Map<string, string>>(new Map<string, string>());

    const currency = defaultCurrencyItem;
    const elementColor = getTaxonomyTagColor(TaxonomyTargetingType.USER);

    useEffect(() => {
        (async () => {
            try {
                const element = await session.restTaxonomy.getElement(TaxonomyElementType.SEGMENT, +segmentId);
                if (element?.audience_segment) {
                    setSegment(element.audience_segment);
                } else {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode === HttpStatusCode.NOT_FOUND) {
                    session.emit(SirdataApiEvent.eventNotFound);
                }
                console.error("Failed to load segment:", e);
            }
        })();
    }, [segmentId]);

    useEffect(() => {
        if (!segment || !!distributions.length) return;
        (async () => {
            try {
                const distributionIds: Map<string, string> = new Map();

                const distributions = await session.getDistributionsByType(TaxonomyElementType.SEGMENT);
                distributions.forEach((distribution) => {
                    distributionIds.set(distribution.name, segment.getDistributionId(distribution));
                });

                const adsId = await session.getAdsId(segment.id);
                if (adsId) {
                    const adsDistribution = Distribution.SIRDATA_ADS;
                    if (!distributions.some((it) => it.name === adsDistribution.name)) {
                        distributions.unshift(adsDistribution);
                    }
                    distributionIds.set(adsDistribution.name, adsId.toString());
                }

                setDistributions(distributions);
                setDistributionIds(distributionIds);
            } catch (e) {
                if (e instanceof ErrorResponse) {
                    console.error("Failed to load distributions:", e);
                }
            }
        })();
    }, [segment, distributions.length]);

    const handleCopyName = (segment: Segment) => {
        copy(`${segment.id} | ${segment.full_name}`);
        setCopiedName(true);
        setTimeout(() => setCopiedName(false), 1000);
    };

    if (!segment) return (<></>);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent>
                <div className="element-details">
                    <div className="details__skin" style={{background: `radial-gradient(circle at -4rem -2rem, ${elementColor} 34.9rem, ${Color.WHITE} 35rem)`}}>
                        <SvgSirdataChip currentColor={Color.WHITE}/>
                    </div>
                    <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START} spacing={FlexContentSpacing.LARGE_PLUS} mobileDirection={FlexContentMobileDirection.COLUMN}>
                        <div className="details__main">
                            <div className="details__main__row">
                                <div className="details__main__header">
                                    <TagUserSegmentType type={segment.data_type}/>
                                    <div className="details__main__header__actions">
                                        <div className="details__main__header__actions__item">
                                            <IconTooltip
                                                icon={isCopiedName ? Action.COPIED.icon : Action.COPY.icon}
                                                text={textTaxonomy("action.copy_segment_name")}
                                                onClick={() => handleCopyName(segment)}
                                            />
                                        </div>
                                        <div className="details__main__header__actions__item">
                                            <FavoriteButton element={segment.toTaxonomyElement()}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="details__main__parent">
                                    {segment.top_path_element?.name}
                                </div>
                                <h1 className="details__main__title">
                                    <span>{segment.name}</span>
                                    {segment.isNew() && <TagNew big/>}
                                </h1>
                                <div className="details__main__description">{segment.description}</div>
                                <div className="details__main__tags">
                                    {segment.cookieless && <TagCookieless/>}
                                    <TagActivationType type={TaxonomyActivationType.SELF_SERVICE}/>
                                    {segment.hasDataType(SegmentDataType.MODELING) && <TagKantarMedia/>}
                                    {segment.isTheTradeDeskEUID() && <TagEuid/>}
                                </div>
                            </div>
                            <div className="details__main__row">
                                <div className="details__main__infos" style={{color: elementColor}}>
                                    <div className="details__main__infos__info">
                                        <div className="details__main__infos__info__name">
                                            <Icon name="equalizer" cssClass="details__main__infos__info__name__icon"/>
                                            <span>{textTaxonomy("info.volume")}</span>
                                        </div>
                                        <div className="details__main__infos__info__value">
                                            {getSegmentStepFromValue(segment.step_volume)}
                                        </div>
                                    </div>
                                    <div className="details__main__infos__info">
                                        <div className="details__main__infos__info__name">
                                            <Icon name="sell" cssClass="details__main__infos__info__name__icon"/>
                                            <span>{textTaxonomy("info.cpm")}</span>
                                        </div>
                                        <div className="details__main__infos__info__value">
                                            {segment.getPrice(currency.currency) / 100} {`${currency.symbol}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LayoutRows cssClass={"details__more"}>
                            <div className="details__more__section">
                                <h2 className="details__more__section__title">
                                    <Icon name="bookmark" cssClass="details__more__section__title__icon" colorIcon={elementColor}/>
                                    <span>{textTaxonomy("details.distribution.title")}</span>
                                </h2>
                                <Paragraph cssClass={"details__more__section__text"} withSpacing>{textTaxonomy("details.distribution.text")}</Paragraph>
                                <div className="details__more__section__content">
                                    <div className="details__more__section__platforms">
                                        {distributions.map((item) => ({distribution: item, value: distributionIds.get(item.name)})).map(({distribution, value}) =>
                                            <div className="details__more__section__platforms__item" key={distribution.name}>
                                                <div className="details__more__section__platforms__item__platform">
                                                    <div className="details__more__section__platforms__item__platform__logo">
                                                        <DistributionLogo distribution={distribution}/>
                                                    </div>
                                                    <div className="details__more__section__platforms__item__platform__name">{distribution.label}</div>
                                                </div>
                                                <div className="details__more__section__platforms__item__value">
                                                    <div className="details__more__section__platforms__item__value__action">
                                                        {value ?
                                                            <ValueCopier value={value} tooltip={textTaxonomy("action.copy_id")}/> :
                                                            <Button
                                                                size={ButtonSize.SMALL}
                                                                style={ButtonStyle.PRIMARY_MIDNIGHT}
                                                                icon={{name: "ios_share"}}
                                                                onClick={() => UIEventManager.emit(ModalContactUiEvent, {
                                                                    topicValue: "DISTRIBUTION",
                                                                    topicLabel: t("contact.topic.DISTRIBUTION"),
                                                                    message: t("contact.message.DISTRIBUTION", {type: "segment", name: `${segment.name} (${segment.id})`, platform: distribution?.label})
                                                                })}
                                                            >
                                                                {textCommon(Action.ACTIVATE.labelKey)}
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </LayoutRows>
                    </FlexContent>
                </div>
            </MainContent>
        </Wrapper>
    );
}

export default UserSegment;
