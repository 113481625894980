import {ApiModel} from "../../../../common/api/model/ApiModel";
import {Keyword} from "./Keyword";
import {pathContextualCustomCategory, pathContextualCustomCategoryKeywords, pathContextualCustomCategoryRelevancy} from "../../../../utils/constants";
import {CustomCategoryConfigStep} from "./CustomCategoryConfigStep";
import {Threshold} from "./Threshold";
import {PathHelper} from "../../../../common/utils/PathHelper";
import {Distribution} from "../../distribution/Distribution";

export class CustomCategory extends ApiModel {
    id: number = 0;
    id_group: number = 0;
    id_partner: number = 0;
    name: string = "";
    description: string = "";
    active: boolean = false;
    pending: boolean = true;
    keywords: Keyword[] = [];
    urls: string[] = [];

    getJsonParameters(): {} {
        return {
            id: this.id,
            id_group: this.id_group,
            id_partner: this.id_partner,
            name: this.name,
            description: this.description,
            active: this.active,
            pending: this.pending,
            keywords: this.keywords
        };
    }

    getThreshold(): number {
        if (!this.keywords.length) return Threshold.THRESHOLD_500;
        return this.keywords[0]?.threshold;
    }

    getRoute(step?: CustomCategoryConfigStep): string {
        let routeWithId = pathContextualCustomCategory;
        if (step) {
            switch (step) {
                case CustomCategoryConfigStep.Keywords:
                    routeWithId = pathContextualCustomCategoryKeywords;
                    break;
                case CustomCategoryConfigStep.Relevancy:
                    routeWithId = pathContextualCustomCategoryRelevancy;
                    break;
                default:
                    routeWithId = pathContextualCustomCategory;
                    break;
            }
        }
        return PathHelper.buildPathWithId(routeWithId, this.id_group);
    }

    getDistributionId(distribution: Distribution): string {
        switch (distribution.name) {
            case Distribution.SIRDATA_API.name:
                return this.id.toString();
            default:
                return "";
        }
    }
}
