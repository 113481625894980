import {Rest} from "../../common/api/rest/Rest";
import {Segment} from "../model/segment/Segment";
import {Distribution} from "../model/distribution/Distribution";

export class RestAudiencePortal extends Rest {
    rootPath = "/audience-api/portal";

    getHomeAudiences(): Promise<Segment[]> {
        return this._client.get(`${this.rootPath}/home/audience`, Segment) as Promise<Segment[]>;
    }

    getHomePlatforms(): Promise<Distribution[]> {
        return this._client.get(`${this.rootPath}/home/platform`, Distribution) as Promise<Distribution[]>;
    }
}
